import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import "./styles.scss";
import { isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
import images from "react-payment-inputs/images";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomInput from "./CustomInput";
import { giveWithCard } from "../../store/actions/userDashboardActions/give";
import CustomAppBar from "../../components/Nav/GiveCampaignV2AppBar";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import IntlCurrencyInput from "react-intl-currency-input";
import {
  CircularProgress,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import { StyledRadio } from "./CustomRadio";
import moment from "moment";
import CustomSelect from "./CustomSelect";

const currencyConfig = {
  locale: "en-US",
  formats: {
    number: {
      USD: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      GBP: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      NGN: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      CAD: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      EUR: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const currencySymbols = {
  USD: "$",
  GBP: "£",
  NGN: "₦",
  CAD: "C$",
  EUR: "€",
};

const items = [
  {
    label: "One-time",
    value: "once",
  },
  { label: "Monthly", value: "Monthly" },
];

const recurringLabels = {
  Monthly: "Monthly",
  once: "One-time",
};

const GiveForm = ({
  onSubmit,
  donatedAmount,
  giveInfo,
  logo,
  showLogo,
  appBarClass,
  classes,
  menu,
  themeMode,
  handleHomeBtnClick,
  indexPage,
  isDynamicGiveCampaign = false,
}) => {
  const { handleSubmit, errors, control, watch, setValue, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      recurring: "once",
    },
  });

  const dispatch = useDispatch();
  const MAX_AMOUNT_LENGTH = giveInfo?.maximumAmount?.length;

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const { loading } = useSelector((state) => state.giveWithANewCardData);
  const ipData = useSelector((state) => state.ipData);

  const [isEditing, setIsEditing] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [showCardNumber, setShowCardNumber] = useState({
    cvc: true,
    cardNumber: true,
  });
  const [formData, setFormData] = useState({});
  const [checked, setChecked] = useState(false);
  const [paymentErr, setPaymentErr] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");

  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const cardType = meta?.cardType?.displayName;

  let imagePath = "";
  if (cardType === "Visa") {
    imagePath = "/images/Visa.png";
  } else if (cardType === "Mastercard") {
    imagePath = "/images/master-card.jpg";
  } else if (cardType === "American Express") {
    imagePath = "/images/american-express.png";
  } else if (cardType === "Discover") {
    imagePath = "/images/Discover.png";
  }

  let amount = watch("giveAmount");
  let memo = watch("memo");

  useEffect(() => {
    if (!isEmpty(formData)) {
      Object.entries(formData).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else setValue("giveAmount", donatedAmount);
  }, [formData, setValue, isEditing]);

  const handleEditChange = () => {
    window.scrollTo(0, 0);
    setPaymentErr("");
    setIsEditing((prev) => !prev);
  };

  const handleToggle = () => {
    setChecked((prev) => !prev);
  };

  const getLastFourDigits = (str) => {
    return str.slice(-4);
  };

  const handleContinue = (data) => {
    if (isEditing) {
      setIsEditing(false);
      const cardLastFourDigits = getLastFourDigits(cardDetails.cardNumber);
      setLastFourDigits(cardLastFourDigits);
    }
    setFormData(data);
  };

  const hanldeSubmitForm = (data) => {
    if (isEditing) {
      window.scrollTo(0, 0);
      return handleContinue(data);
    }
    onSubmitForm(formData);
  };

  const handleTextType = (name) => (showCardNumber[name] ? "text" : "password");

  const handleFocus = ({ target: { name } }) => {
    setShowCardNumber({
      ...showCardNumber,
      [name]: true,
    });
  };

  const handleBlur = ({ target: { name } }) => {
    setShowCardNumber({
      ...showCardNumber,
      [name]: false,
    });
  };

  const handleCardChange = ({ target: { value, name } }) => {
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const onSubmitForm = (data) => {
    const { giveAmount, memo, recurring, ...rest } = data;
    const trimCardNum = cardDetails.cardNumber?.replace(/ /g, "");
    const amount = convertAmount();
    const payload = {
      cardNumber: window.btoa(trimCardNum),
      amount: amount,
      contributionType: isDynamicGiveCampaign
        ? giveInfo?.contributionType[0]
        : giveInfo?.contributionType,
      cvv: cardDetails.cvc,
      expirationMonth: cardDetails.expiry.substring(0, 2),
      expirationYear: `20${cardDetails.expiry.substring(5)}`,
      orgId: orgInfo?.orgId,
      pageSource: "gc",
      memo: checked ? memo : "",
      recurring,
      recurringStartDate:
        recurring !== "once" ? moment().format("MM/DD/YYYY") : undefined,
      ...rest,
    };
    return dispatch(giveWithCard(payload, handleCallback));
  };

  const handleCallback = (data) => {
    if (data?.responseCode && data?.responseCode === "01")
      return setPaymentErr(data?.responseMessage);
    reset();
    setCardDetails({});
    onSubmit();
  };

  let paymentDataStyle = "payment-data w-full";
  if (!isEditing) {
    paymentDataStyle = "edit-payment-data";
  }

  let formContainerBackground = "";
  let amountCardBackground = "";
  let donateInput = "";
  let dollarSignColor = "";
  let formAmountColor = "";
  let donationTextColor = "";
  let formMessageColor = "";
  let phoneInputStyle = "";
  let textarea = "";
  let containerBg = "";
  let digitColor = "";
  let expiryColor = "";
  let errorBG = "";

  if (themeMode === "light") {
    formContainerBackground = "form-container-light-background";
    amountCardBackground = "amount-card-light-background ";
    donateInput = "donate-light-input";
    dollarSignColor = "light-dollar-sign";
    formAmountColor = "light-form-amount";
    donationTextColor = "light-form-donation-text";
    formMessageColor = "light-form-message";
    containerBg = "light-edit-icon-bg";
    digitColor = "light-card-digit-ending";
    expiryColor = "light-expiry-date";
    errorBG = "light-error-bg";
  } else if (themeMode === "dark") {
    formContainerBackground = "form-container-dark-background";
    amountCardBackground = "amount-card-dark-background ";
    donateInput = "donate-dark-input";
    dollarSignColor = "dark-dollar-sign";
    formAmountColor = "dark-form-amount";
    donationTextColor = "dark-form-donation-text";
    formMessageColor = "dark-form-message";
    phoneInputStyle = "phone-input";
    textarea = "dark-textarea";
    containerBg = "dark-edit-icon-bg";
    digitColor = "dark-card-digit-ending";
    expiryColor = "dark-expiry-date";
    errorBG = "dark-error-bg";
  }

  const convertAmount = () => {
    let donateAmount = amount || formData?.giveAmount;
    if (!donateAmount) return 0.0;
    if (typeof donateAmount === "number") {
      donateAmount = `${donateAmount}.00`;
    }
    const numericString = `${donateAmount}`.replace(/\D/g, "");
    return parseFloat(Number(numericString) / 100).toFixed(2);
  };

  function formatToThousandth(value, locale = "en-US") {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }

  const validateMinMax = (value, min, max) => {
    let val = value;
    if (typeof value === "string") {
      const numericString = `${value}`.replace(/\D/g, "");
      val = parseFloat(Number(numericString) / 100).toFixed(2);
    }
    if (val < min) {
      return `Value must be greater than or equal to ${min}`;
    }
    if (val > max) {
      return `Value must be less than or equal to ${max}`;
    }
    return true; // Validation passed
  };

  return (
    <div className={formContainerBackground}>
      <CustomAppBar
        classes={classes}
        showLogo={showLogo}
        logo={logo}
        appBarClass={appBarClass}
        menu={menu}
        handleHomeBtnClick={handleHomeBtnClick}
        indexPage={indexPage}
        isDynamicGiveCampaign={isDynamicGiveCampaign}
      />

      <form className="form-section" onSubmit={handleSubmit(hanldeSubmitForm)}>
        <div className="form-container">
          <h2>
            {isDynamicGiveCampaign
              ? giveInfo?.header
              : `Give to The Lord’s Table Food Pantry`}
          </h2>
          {isDynamicGiveCampaign && (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "medium",
                textAlign: "center",
              }}
              className="mt-5"
            >
              {giveInfo?.subHeader}
            </p>
          )}
          <div className="form-donation">
            {!isDynamicGiveCampaign && <h3>{isEditing ? "Donate" : "Gift"}</h3>}
            {(!isEditing || !isDynamicGiveCampaign) && (
              <div className="form-card-container on-edit">
                {/* {!isDynamicGiveCampaign && ( */}
                <div className="w-100">
                  <div className="d-flex justify-content-between align-center">
                    <p className={`form-amount ${formAmountColor}`}>
                      {currencySymbols[orgInfo?.defaultCurrency]}
                      {formatToThousandth(convertAmount())}{" "}
                      {!isEditing && `•${recurringLabels[formData?.recurring]}`}
                    </p>

                    {isEditing && (
                      <Controller
                        as={
                          <RadioGroup row style={{ columnGap: "10px" }}>
                            {items.map(({ label, value }, index) => (
                              <FormControlLabel
                                value={value}
                                key={value}
                                control={<StyledRadio className="p-0 mx-2" />}
                                label={label}
                                className="m-0"
                              />
                            ))}
                          </RadioGroup>
                        }
                        name={"recurring"}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue="once"
                      />
                    )}
                  </div>
                  <p className={`form-donation-text ${donationTextColor}`}>
                    {isDynamicGiveCampaign
                      ? `${formData?.contributionType}`
                      : "Donation"}
                  </p>
                </div>
                {/* )} */}
                {!isEditing && (
                  <div className={`edit-icon-container ${containerBg}`}>
                    {themeMode === "light" ? (
                      <img
                        src="/images/edit-icon.png"
                        alt="Edit"
                        className="edit-icon"
                        onClick={handleEditChange}
                      />
                    ) : (
                      <img
                        src="/images/dark-edit-icon.png"
                        alt="Edit"
                        className="edit-icon"
                        onClick={handleEditChange}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {isEditing && (
              <>
                <div
                  className={`form-donation-amount-card ${amountCardBackground}`}
                >
                  <Controller
                    as={
                      <IntlCurrencyInput
                        currency={"USD"}
                        config={currencyConfig}
                        className={`form-donation-card-amount mb-0 text-center ${donateInput} w-100`}
                        inputMode="numeric"
                        autoFocus={
                          donatedAmount === 0.0 || isDynamicGiveCampaign
                        }
                        max={MAX_AMOUNT_LENGTH || 5}
                      />
                    }
                    name="giveAmount"
                    control={control}
                    rules={{
                      required: "Amount is required",
                      validate: (value) => {
                        return validateMinMax(
                          value,
                          Number(giveInfo.minimumAmount),
                          Number(giveInfo.maximumAmount)
                        );
                      },
                    }}
                    defaultValue=""
                  />
                </div>
                {errors?.giveAmount && (
                  <p className="text-danger">{errors?.giveAmount?.message}</p>
                )}
              </>
            )}
          </div>
          {isDynamicGiveCampaign && isEditing && (
            <div className="form-card-container mt-8">
              {giveInfo?.contributionType.length > 1 && (
                <div className="form-data w-100">
                  <label>Giving Type</label>
                  <Controller
                    as={
                      <CustomSelect name="contributionType" errors={errors}>
                        {giveInfo?.contributionType?.map((fund) => (
                          <option key={fund} value={fund}>
                            {fund}
                          </option>
                        ))}
                      </CustomSelect>
                    }
                    name="contributionType"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    defaultValue={
                      giveInfo?.contributionType
                        ? giveInfo?.contributionType[0]
                        : ""
                    }
                  />
                </div>
              )}
              <div className="form-data w-100 mt-2">
                <label>Frequency</label>
                <Controller
                  as={
                    <RadioGroup row style={{ columnGap: "10px" }}>
                      {items.map(({ label, value }, index) => (
                        <FormControlLabel
                          value={value}
                          key={value}
                          control={<StyledRadio className="p-0 mx-2" />}
                          label={label}
                          className="m-0"
                        />
                      ))}
                    </RadioGroup>
                  }
                  name={"recurring"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue="Monthly"
                />
              </div>
            </div>
          )}
          <h3>About You</h3>
          {!isEditing ? (
            <div className="form-card-container on-edit">
              <div className="edit-about-you">
                <img src="/images/avatar.png" alt="Avatar" />
                <div>
                  <p className="form-amount">{`${formData?.firstName} ${formData?.lastName}`}</p>
                  <p className="form-donation-text">{`${formData?.email}`}</p>
                </div>
              </div>
              <div className={`edit-icon-container ${containerBg}`}>
                {themeMode === "light" ? (
                  <img
                    src="/images/edit-icon.png"
                    alt="Edit"
                    className="edit-icon"
                    onClick={handleEditChange}
                  />
                ) : (
                  <img
                    src="/images/dark-edit-icon.png"
                    alt="Edit"
                    className="edit-icon"
                    onClick={handleEditChange}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="form-card-container">
              <div className="about-you">
                <div className="form-data">
                  <label>First Name</label>
                  <Controller
                    as={<CustomInput type="text" errors={errors} />}
                    name="firstName"
                    control={control}
                    rules={{
                      required: "First Name is required",
                      minLength: {
                        value: 2,
                        message: "Value must be at least 2 characters",
                      },
                      maxLength: {
                        value: 64,
                        message: "Value must be at most 64 characters",
                      },
                      pattern: {
                        value: /^[A-Za-z]+$/,
                        message: "Only letters are allowed",
                      },
                    }}
                    defaultValue=""
                  />
                </div>
                <div className="form-data">
                  <label>Last Name</label>
                  <Controller
                    as={<CustomInput type="text" errors={errors} />}
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Last Name is required",
                      minLength: {
                        value: 2,
                        message: "Value must be at least 2 characters",
                      },
                      maxLength: {
                        value: 64,
                        message: "Value must be at most 64 characters",
                      },
                      pattern: {
                        value: /^[A-Za-z]+$/,
                        message: "Only letters are allowed",
                      },
                    }}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="about-you-data">
                <label>Email</label>
                <Controller
                  as={<CustomInput type="email" errors={errors} />}
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  defaultValue=""
                />
                <label>Phone Number</label>
                <div>
                  <Controller
                    as={
                      <PhoneInput
                        international
                        withCountryCallingCode
                        countryCallingCodeEditable={false}
                        defaultCountry={ipData ? ipData.country_code : null}
                        className={`${phoneInputStyle} ${
                          errors["mobile"] ? "border-danger border" : ""
                        }`}
                        style={{
                          backgroundColor:
                            themeMode === "light" ? "#FFFFFF" : "#020617",
                        }}
                      />
                    }
                    name={"mobile"}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return true;
                        }
                        if (!isValidPhoneNumber(value)) {
                          return "Invalid phone number";
                        }
                        return true;
                      },
                      required: false,
                    }}
                    defaultValue=""
                  />
                  {errors?.["mobile"]?.message && (
                    <p className="text-danger">{errors?.["mobile"]?.message}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <h3>Payment</h3>
          <div className="form-card-container">
            <PaymentInputsWrapper
              {...wrapperProps}
              className="w-full credit-card"
            >
              <div className={`${paymentDataStyle}`}>
                {isEditing ? (
                  <div className="credit-card-style">
                    <div className="about-you-data card-number position-relative">
                      <label>Card Number</label>
                      <input
                        {...getCardNumberProps({
                          onChange: handleCardChange,
                          onBlur: handleBlur,
                          onFocus: handleFocus,
                        })}
                        value={cardDetails.cardNumber || ""}
                        name="cardNumber"
                        type={handleTextType("cardNumber")}
                        placeholder="Card number"
                        maxLength="19"
                        className={`pl-4 pr-13 ${
                          meta?.erroredInputs?.["cardNumber"] &&
                          meta?.touchedInputs?.["cardNumber"]
                            ? "border-danger border"
                            : ""
                        }`}
                        inputMode="numeric"
                        required
                      />
                      <svg
                        {...getCardImageProps({ images })}
                        style={{ top: "56%", right: "12px" }}
                        className="position-absolute"
                      />
                    </div>
                    <div className={`editing`}>
                      <div className={`form-data expiry`}>
                        <label>Expiry</label>
                        <input
                          placeholder="MM/YY"
                          {...getExpiryDateProps({
                            onChange: handleCardChange,
                          })}
                          name="expiry"
                          className={`px-4 ${
                            meta?.erroredInputs?.["expiryDate"] &&
                            meta?.touchedInputs?.["expiryDate"]
                              ? "border-danger border"
                              : ""
                          }`}
                          inputMode="numeric"
                          required
                          value={cardDetails.expiry || ""}
                        />
                      </div>
                      <div className={`form-data cvv`}>
                        <label>CVV</label>
                        <input
                          placeholder="CVV"
                          maxLength={cardType === "American Express" ? 4 : 3}
                          name="cvv"
                          required
                          inputMode="numeric"
                          value={cardDetails.cvc || ""}
                          {...getCVCProps({
                            onChange: handleCardChange,
                            onBlur: handleBlur,
                            onFocus: handleFocus,
                          })}
                          className={`px-4 ${
                            meta?.erroredInputs?.["cvc"] &&
                            meta?.touchedInputs?.["cvc"]
                              ? "border-danger border"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="edit-card-number">
                      <img
                        src={imagePath}
                        alt="Visa"
                        style={{ width: "60px" }}
                      />
                      <div className="credit-card-expiry-detail">
                        <p className={`card-digit-ending ${digitColor}`}>
                          {cardType} ending ••••{lastFourDigits}
                        </p>
                        <p className={`expiry-date ${expiryColor}`}>
                          Exp. date {cardDetails.expiry}
                        </p>
                      </div>
                    </div>
                    {paymentErr && (
                      <div className={`edit-icon-container ${containerBg}`}>
                        {themeMode === "light" ? (
                          <img
                            src="/images/edit-icon.png"
                            alt="Edit"
                            className="edit-icon"
                            onClick={handleEditChange}
                          />
                        ) : (
                          <img
                            src="/images/dark-edit-icon.png"
                            alt="Edit"
                            className="edit-icon"
                            onClick={handleEditChange}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </PaymentInputsWrapper>
            {paymentErr && (
              <div className={`unsuccessful-payment ${errorBG}`}>
                <img src="/images/warning-icon.png" alt="Warning icon" />
                <p>{paymentErr}</p>
              </div>
            )}
          </div>
          {isEditing && (
            <div className="form-donation-message">
              <div
                className={`form-message d-flex align-items-center ${formMessageColor}`}
              >
                <img
                  src="/images/message-icon.svg"
                  alt="Message icon"
                  className="w-30px"
                />
                <p>Include a message for your donation</p>
              </div>
              <Toggle checked={checked} onChange={handleToggle} icons={false} />
            </div>
          )}
          <div className="form-footer">
            {isEditing && checked && (
              <>
                <Controller
                  as={
                    <textarea
                      placeholder="Enter your message here ...."
                      cols={5}
                      rows={5}
                      maxLength={100}
                      minLength={2}
                      className={textarea}
                    />
                  }
                  name="memo"
                  control={control}
                  rules={{
                    required: false,
                    minLength: {
                      value: 2,
                      message: "Value must be at least 2 characters",
                    },
                    maxLength: {
                      value: 100,
                      message: "Value must be at most 100 characters",
                    },
                  }}
                  defaultValue=""
                />
                <p className="text-right mt-1">{memo?.length || 0}/100</p>
              </>
            )}
            {isEditing && (
              <button type="submit" disabled={wrapperProps?.error}>
                Continue
              </button>
            )}
            {!isEditing && (
              <button
                type="submit"
                disabled={paymentErr.length || loading}
                className="d-flex justify-content-center align-items-center"
              >
                {loading && (
                  <CircularProgress
                    size={18}
                    className={"mr-4"}
                    color="white"
                    thickness={4.5}
                  />
                )}
                Process my giving
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GiveForm;
