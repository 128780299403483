import React, { useEffect } from 'react'
import { MenuItem, TextField } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

export const DateComponent = ({ getDate, date, title='Birthday' }) => {
  const orgInfoList = useSelector(
    (state) => state.orgInfoList.responseData,
    shallowEqual
  );  
  const initialState = { year: '0000', month: '01', days: '01' };  

  const [data, setData] = useState(initialState);
  const [monthDay, setMonthDay] = useState([])

  const months = orgInfoList?.daysAndMonthsDropDownList || [];

  const handleChange = ({ target: { name, value } }) => {
    const temp = { ...data };
    temp[name] = value;
    if (name === "month") temp.days = "";
    setData(temp);
    let { month, days, year } = temp;
    if (!month) month = "00";
    if (!days) days = "00";
    if (!year) year = "0000";
    const date = [year, month, days].join("-");
    getDate(date);
  };

  let currentYear = moment().year();
  const years = [];
  let start = currentYear - 100;
  for (let i = start; i <= currentYear; i++) {
    years.push(i);
  }

  const isRequired = (data) => {
    switch (data) {
      case "":
      case "00":
      case "0000":
      case !date:
        return false;
      default:
        return true;
    }
  };


  const dateValue = (date) => {
    switch (date) {
      case "":
      case "00":
      case "0000":
      case !date:
        return "";
      default:
        return date;
    }
  };

  useEffect(() => {
    if (date) {
      const [year, month = "", days = ""] = date?.split("-") || [];
      const adjustedMonth = month === "00" ? "01" : month;
      const initialState = { year, month: adjustedMonth, days };
      setData(initialState)
    }
  }, [date])
  

  useEffect(() => {
    if (orgInfoList) {
      const monthDayMap = orgInfoList.daysAndMonthsDropDownList?.reduce((obj, item) => {
        obj[item.name] = item.days;
        return obj;
      }, {}) || {}; 
         
      setMonthDay(monthDayMap[data?.month] || []); 
    }
    
  }, [orgInfoList, date, data])  
  

  return (
    <div className="date-component-container">
      <TextField
        className="w-full mb-5"
        label={`${title} Month`}
        name="month"
        size="small"
        variant="outlined"
        select
        fullWidth
        value={dateValue(data?.month)}
        onChange={handleChange}
        children
        required={isRequired(data.days)}
        >
        <MenuItem value={"00"}>N/A</MenuItem>
        {months?.map((item, ind) => (
          <MenuItem value={item.name} key={ind}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      {monthDay.length > 0 && (
        <TextField
          className="w-full mb-5"
          label={`${title} Day`}
          name="days"
          size="small"
          variant="outlined"
          select
          fullWidth
          value={dateValue(data?.days)}
          onChange={handleChange}
          defaultValue=""
          children
          required={isRequired(data.month)}
          disabled={!data?.month}
        >
          <MenuItem value={"00"}>N/A</MenuItem>
          {monthDay.map((item, ind) => (
            <MenuItem value={item} key={ind}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        className="w-full mb-5"
        label={`${title} Year`}
        name="year"
        size="small"
        variant="outlined"
        select
        fullWidth
        value={dateValue(data?.year)}
        onChange={handleChange}
        defaultValue=""
        children
      >
        <MenuItem value={"0000"}>N/A</MenuItem>
        {years.map((item, ind) => (
          <MenuItem value={item} key={ind}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};
